.ZipcodeModal-container {
  padding: 0px 20px;

  /* Set max height to bigger than the content, such that when a shrink transform happens, the height can shrink properly with transition, i.e. 500px --> 0px */
  max-height: 500px;
  opacity: 1;
  /* Hide content when shrinking height of container */
  transition: all 1s;
  text-align: center;
}
p.text-semibold {
  font-size: 20px;
}
input.input-zip {
  font-size: 15px;
  letter-spacing: 3px;
}
.close-modal.zip-close {
  position: absolute;
  width: 100%;
  bottom: -13%;
  text-align: center;
  left: 0;
}
.close-modal span {
  color: white;
  font-size: 40px;
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
}
.input-full {
  width: 100%;
  height: 40px;
  outline: none;
  border: none;
  box-shadow: inset 0px 1px 1px 0 rgba(1, 1, 1, 0.11);
  padding: 20px;
  box-sizing: border-box;
}
.input-grey {
  background: #f7f7f7;
}
.rounded {
  border-radius: 22px;
}
/* btn btn-full btn-xen */
.input-wrapper {
  /* background: red; */
  padding: 4px 0;
}
.btn-full {
  width: 100%;
  height: 40px;
  margin: 5px 0;
  outline: none;
  border: none;
  color: white;
  font-size: 15px;
  font-weight: 500;
}
.btn-fb {
  background: #4267b2;
}
.btn-google {
  background: #d6492e;
}
.btn-xen {
  background: #8baa3e;
}
.horiz-line {
  text-align: center;
  /* Prevent wrapping to next line */
  white-space: nowrap;
  margin: 5px 0;
}
.horiz-line::before,
.horiz-line::after {
  content: "";
  width: 45%;
  height: 1px;
  background: #c9c9c9;
  display: inline-block;
  vertical-align: middle;
}
.horiz-line::before {
  margin-right: 3%;
}
.horiz-line::after {
  margin-left: 3%;
}
.ZipcodeModal-link {
  color: #666;
}
