.Header-container {
  background: #fff;
  padding-bottom: 5px;
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
}
img {
}
.Top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 20px;
}
.Mid-bar {
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
}
.Mid-img-holder {
  position: relative;
  display: flex;
  flex-direction: row;
}
.zp-icon-holder {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Mid-img-holder img.zp-icon {
}
.Mid-span-holder {
  margin-top: 5px;
  font-size: 9px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
}
.zp-span-holder {
  flex: 1;
  justify-content: center;
  display: flex;
  position: relative;
}
.zp-span-holder::after {
  content: "";
  position: absolute;
  transform: scaleX(0.3);
  width: 1px;
  right: 0;
  height: 30px;
  bottom: 0;
  background: black;
}
.zp-span-holder:last-child::after {
  display: none;
}
.zp-icon.login {
  width: 20px;
}
.zp-icon.cart {
  width: 24px;
}
.zp-icon.hamburger {
  width: 22px;
}
.zp-icon.search {
  padding-left: 10px;
  padding-right: 5px;
  width: 32px;
}
.zp-icon.zipcode {
  width: 17px;
}
.zp-logo {
  max-width: 80px;
}
.inputbox {
  display: flex;
  align-items: center;
  background: #f7f7f7;
  outline: none;
  height: 38px;
  max-width: 400px;
  width: 70%;
  border-radius: 20px;
  box-shadow: inset 0px 2px 3px 0 rgba(1, 1, 1, 0.11);
}
.Header-input {
  background: #f7f7f7;
  border-radius: 20px;
  border: none;
  outline: none;
  width: 100%;
}
