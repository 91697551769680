.feature-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 160px;
  max-width: 160px;
  background: #fff;
  border-radius: 5px;
  margin-right: 7px;
  /* box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.1); */
}
.feature-card-content {
  padding: 5% 10%;
  position: relative;
}
.feature-card-content img.addtoCart {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.delivery-tag {
  color: #8baa3e;
  font-size: 11px;
  display: block;
  margin-bottom: 16px;
}
.price-unit-tag {
  font-size: 12.5px;
  font-weight: bold;
}
.description-tag-holder {
  height: 2.5rem;
  overflow: hidden;
}
.description-tag {
  max-width: 160px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  line-height: 1em;
}
.description-tag,
.title-tag {
  font-size: 12.5px;
  color: #666;
  letter-spacing: 0.2px;
}
.bold-title {
  margin: 10px 0 2px 0;
  font-size: 18px;
}
.weight-tag {
  font-size: 11px;
  color: #999;
}
.fullwidthImg {
  /* flex: 1;
  display: block;
  margin: 0 auto; */
  height: 120px; /* Constant height for all images, may not be suitable...? */
  object-fit: contain;
}
