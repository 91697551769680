.CategoryBox-container {
  position: relative;
  width: 95%;
  height: 29vw;
  /* max-width: 160px; */
  /* margin: 1%; */
  /* height: auto; */
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
}
span.img-wrap {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: max-content;
  text-align: right;
  pointer-events: none; /* Prevent accidental clicks on this oversized-span */
}
.catImg {
  margin-bottom: -4px; /*Gets rid of ghost space at the bottom*/
  z-index: 1;
  width: calc(100% / 3);
}

.CategoryBox-header {
  position: relative;
  z-index: 1;
  height: 40px;
  max-width: 85%;
  padding: 5% 0% 0% 10%;
  font-size: 14.5px;
}
.CategoryBox-imgHolder {
  position: relative;
  /* display: flex; */
  /* justify-content: flex-end; */
}
@media (min-width: 500px) {
  .catImg {
    margin-bottom: -4px;
    z-index: 1;
    max-width: max-content;
    width: 70%;
  }
  span.img-wrap {
    display: block;
    width: auto;
  }
}
