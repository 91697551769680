.cartSummary-container * {
  /* background: rgba(255, 0, 0, 0.1); */
}
.cartSummary-container {
  background: white;
  padding: 3% 5%;
}
.cartSummary-table {
  width: 100%;
}
tr,
td {
  padding: 3% 0;
}
td:nth-child(2) {
  text-align: right;
}
